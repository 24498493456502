// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs
//= require popper
//= require bootstrap

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Rails.start();
// Turbolinks.start();
ActiveStorage.start();

// //window.document.onload = function(e){
// window.onload = function(){
// // document.addEventListener("DOMContentLoaded", function() {
//     document.getElementById("sidebarButton").onclick = function(){
//         document.getElementById('content').style.opacity = '0.2';
//         let sidebar = document.getElementById("mySidebar");
//         sidebar.style.width = '50vw';
//     };
//
//     document.getElementById("closeButton").onclick = function(){
//         document.getElementById('content').style.opacity = '1.0';
//         let sidebar = document.getElementById("mySidebar");
//         sidebar.style.width = '0%';
//     };
// // });
// };
